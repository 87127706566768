<template>
  <div>
    <button id="orderModalBtn" style="display: none; overflow: auto!important;" data-toggle="modal"
            data-target="#orderModal"
            @click="prepareCost"></button>
    <div class="modal fade" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="orderModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document" style="min-width: 85%;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="orderModalLabel">Інформація про послугу</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="pl-4 orderModal__towork" v-if="ToWork">
              Створено нове замовлення послуг № {{ order.doc_no }}
            </p>

            <div class="products_table_wrapper" v-if="Products">
              <table class="table table-bordered">
                <thead>
                <th>Найменування товару/послуги</th>
                <th>Кількість</th>
                <th>Од. Вим.</th>
                <th>Ціна</th>
                <th>Сума без знижки</th>
                <th>Знижка</th>
                <th>Сума</th>
                </thead>
                <tbody>
                <tr v-for="(product, index) in Products" :key="index">
                  <td>{{ product.Name }}</td>
                  <td>{{ product.Qt }}</td>
                  <td>{{ unit(product.Unit) }}</td>
                  <td>{{ (product.SumNoDiscount / product.Qt).toFixed(2) }}</td>
                  <td>{{ product.SumNoDiscount.toFixed(2) }}</td>
                  <td>{{ product.SumDiscount.toFixed(2) }}</td>
                  <td>{{ product.Sum.toFixed(2) }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <br>
            <p class="pl-4 pr-5">
              <strong class="font-weight-normal">
                Попередня сума: <h4 class="float-right">{{ sum.toFixed(2) }} грн.</h4>
            </strong>
            </p>

            <p class="pl-4 pr-5">
              <strong class="font-weight-normal" v-if="!Production && Ready">
                Дата готовності: <h4 class="float-right">{{ Ready }} (через {{Days}} днів)</h4>
              </strong>
              <strong class="font-weight-normal" v-else>
                Помилка постановки в план виробництва - план завантажений!
              </strong>
            </p>

            <hr>
            <!--                        <div class="col-6 orderModal__additional">-->
            <!--                            <p class="mb-3 pl-4">Додатково</p>-->
            <!--                            <div class="custom-control custom-checkbox">-->
            <!--                                <input type="checkbox" class="checkbox-input" id="customCheck1" checked-->
            <!--                                       v-model="mat" value="true">-->
            <!--                                <label for="customCheck1">Очистити Матеріали</label>-->
            <!--                            </div>-->
            <!--                            <div class="custom-control custom-checkbox">-->
            <!--                                <input type="checkbox" class="checkbox-input" id="customCheck2" checked-->
            <!--                                       v-model="edg" value="true">-->
            <!--                                <label for="customCheck2">Очистити Кромки</label>-->
            <!--                            </div>-->
            <!--                            <div class="custom-control custom-checkbox">-->
            <!--                                <input type="checkbox" class="checkbox-input" id="customCheck3" checked-->
            <!--                                       v-model="sc" value="true">-->
            <!--                                <label for="customCheck3">Очистити Стяжки</label>-->
            <!--                            </div>-->
            <!--<div class="custom-control custom-checkbox">-->
            <!--<input type="checkbox" class="custom-control-input" id="customCheck4" checked>-->
            <!--<label class="custom-control-label" for="customCheck4">Очистить Группы</label>-->
            <!--</div>-->
            <!--                        </div>-->
          </div>
          <div class="modal-footer">
            <button type="button" class="button button__grey" data-dismiss="modal">Закрити</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sum: 0,
      mat: true,
      edg: true,
      sc: true,
      units: {
        "006": "м",
        "008": "км",
        "018": "пог. м",
        "055": "м2",
        "112": "л",
        "113": "м3",
        "166": "кг",
        "359": "сут",
        "383": "грн",
        "449": "т. км",
        "607": "банк",
        "616": "бухт",
        "625": "лист",
        "728": "пач",
        "736": "рул",
        "778": "упак",
        "796": "шт",
        "798": "тис. шт",
        "812": "ящ",
        "839": "компл",
      }
    }
  },
  computed: {
    response() {
      return this.$store.state.data.response;
    },
    order() {
      return this.$store.state.data.order;
    },
    ToWork() {
      return this.order ? (this.order.doc_no != '' && this.order.doc_no != null) : false;
    },
    Products() {
      return this.response ? this.response.Products : [];
    },
    Production() {
      return this.response ? this.response.ErrorProduction : false;
    },
    Ready() {
      return this.response ?
          this.response.Ready ?
              this.date()
              : false : false;
    },
    Days() {
      return this.response ?
          this.response.Ready_for_day : false;
    }
  },
  methods: {
    date() {
      let dateArray = this.response.Ready.split('T')[0].split('-');
      return dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0];
    },
    unit(v) {
      return this.units[v];
    },
    prepareCost() {
      this.sum = 0;
      if (this.Products != undefined) {
        this.Products.forEach((p) => {
          this.sum += p.Sum;
        })
      }
    },
    clear() {

      app.materials = this.mat ? [] : app.materials;
      app.edges = this.edg ? [] : app.edges;
      app.screeds = this.sc ? [] : app.screeds;
      app.groups = [];
      app.details = [];
      app.detail = {};
      app.materialDefault = this.mat ? 0 : app.materialDefault;
      app.edgeDefault = this.edg ? 0 : app.edgeDefault;
    }
  },
  name: "order-modal"
}
</script>

<style scoped>

</style>