<template>
  <div>
    <div class="modal fade" id="searchModal" tabindex="-1" role="dialog"
         aria-hidden="true" style="overflow: auto!important;">
      <div class="modal-dialog" style="max-width: 1060px" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Пошук</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-4 modal-control">
              <b-input-group class="mt-3">
                <template #append>
                  <b-form-select  v-model="selected_option" :options="options"></b-form-select>
                </template>
                <b-form-input placeholder="Пошук" id="search_item" v-model="query"
                              type="text">
                </b-form-input>
              </b-input-group>

              <div v-if="loading" class="mt-4 mb-4">
                <div class="preloader" ref="preloader">
                  <div class="item-1"></div>
                  <div class="item-2"></div>
                  <div class="item-3"></div>
                  <div class="item-4"></div>
                  <div class="item-5"></div>
                </div>
              </div>
            </div>
            <search-render
                :materials="materials"
                :edges="edges"
                v-if="!loading" :responseData="responseData" :what="what"/>
          </div>
        </div>
      </div>
    </div>
    <recommend-render/>
    <analogs-render/>
  </div>
</template>

<script>
import SearchRender from "./searchRender";
import recommendRender from "./recommendRender.vue";
import analogsRender from "@/components/modals/analogsRender.vue";
import axios from "axios";

const CancelToken = axios.CancelToken;
export default {
  props: ['what', 'materials', 'edges'],
  data() {
    return {
      loading: false,
      responseData: [],
      options: [
        {text: 'Найменування', value: 'full_name'},
        {text: 'Код', value: 'code'},
        {text: 'Артикул', value: 'article'}
      ],
      query: '',
      selected_option: 'full_name',
      searchItemsSource: null,
      bid_price_items: app.bidPriceItems || []
    }
  },
  watch: {
    query(value) {
      this.responseData = [];
      if (value.length >= 3) {
        this.getSearchProducts(value, this.selected_option)
      }
    },
    selected_option(value) {
      if (value) {
        this.getSearchProducts(this.query, value)
      }
    },
    what() {
      this.responseData = [];
      this.query = '';
    }
  },
  methods: {
    getSearchProducts(item, select) {

      if (this.searchItemsSource) {
        this.searchItemsSource.cancel('Cancel previous request');
      }
      this.searchItemsSource = CancelToken.source();
      this.loading = true;
      return axios.post(appconfig.Point + 'api/constructor/v1/' + this.what, {
        by: select,
        search: item,
        take: 20,
        token: (this.$store.state.auth.user.token || null)
      }, {
        cancelToken: this.searchItemsSource.token
      })
          .then(response => {
            this.responseData = response.data;
            this.loading = false;
          })
          .catch(e => {

          });
    },
  },
  components: {
    SearchRender,
    recommendRender,
    analogsRender,
  },
  name: "search"
}
</script>
