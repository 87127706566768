<template>
  <div>
    <div class="details">
      <div class="details__info">
        <div class="accordion" id="accordionExample">

          <div class="accordion_row">
            <div class="accordion_header" id="headingOne" data-toggle="collapse"
                 data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <h3>Матеріали</h3>
            </div>

            <div id="collapseOne" class="collapse accordion_content show" aria-labelledby="headingOne"
                 data-parent="#accordionExample">
              <div>
                <materials :toSearch="toSearch"
                           :materialsDefault="materialsDefault"
                           @interface="toSearch = $event"
                           :materials="materials"
                />
              </div>
            </div>
          </div>

          <div class="accordion_row">
            <div class="accordion_header" id="headingName" data-toggle="collapse"
                 data-target="#collapseName" aria-expanded="false" aria-controls="collapseName">
              <h3>Назва виробу</h3>
            </div>

            <div id="collapseName" class="collapse accordion_content" aria-labelledby="headingName"
                 data-parent="#accordionExample">
              <b-form-input v-model="productName" placeholder="Назва"></b-form-input>
            </div>
          </div>

          <div class="accordion_row">
            <div class="accordion_header" id="headingTwo" data-toggle="collapse"
                 data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <h3>Кромки</h3>
            </div>
            <div id="collapseTwo" class="collapse accordion_content" aria-labelledby="headingTwo"
                 data-parent="#accordionExample">
              <div>
                <edges
                    :edgeDefault="edgeDefault"
                    @interface="toSearch = $event"
                    :edges="edges"
                    :toSearch="toSearch"/>
              </div>
            </div>
          </div>
          <div class="accordion_row">
            <div class="accordion_header" id="headingThree" data-toggle="collapse"
                 data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <h3>
                Стяжки
              </h3>
            </div>
            <div id="collapseThree" class="collapse accordion_content" aria-labelledby="headingThree"
                 data-parent="#accordionExample">
              <div>
                <screeds :screeds="screeds"/>
              </div>
            </div>
          </div>
          <div class="accordion_row">
            <div class="accordion_header" id="headingFour" data-toggle="collapse"
                 data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <h3>
                Параметри замовлення
              </h3>
            </div>
            <div id="collapseFour" class="collapse accordion_content order-content" aria-labelledby="headingFour"
                 data-parent="#accordionExample">

              <div class="order-content__wrapper">
                <div class="order-content__inputs">
                  <div class="item">
                    <input  type="checkbox" class="checkbox-input" id="customer_material"
                           v-model="customer_material" value="true">
                    <label for="customer_material">Матеріал Клієнта</label>
                  </div>

                  <div class="item">
                    <input  type="checkbox" class="checkbox-input" id="customer_edge"
                           v-model="customer_edge"
                           value="true">
                    <label for="customer_edge">Кромка Клієнта</label>
                  </div>
                  <div class="item">
                    <input  type="checkbox" class="checkbox-input" id="edge_size"
                            value="true"
                           v-model="edge_size">
                    <label for="edge_size">Розмір з Кромкою</label>
                  </div>

                  <div class="item">
                    <input  type="checkbox" class="checkbox-input" id="glue" v-model="glue"
                           value="true">
                    <label for="glue">Оклеювання клеєм PUR</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="order-confirm">

              <div class="order-confirm__buttons">
                <button class="button button__grey" :disabled="(this.details.length < 1) || loading"
                        @click="validateOrder">
                  Перевірити деталювання
                </button>
                <button @click="save" class="button">
                  Зберегти
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="visual-container">
        <visual
            :detail="selectedDetail"
            :edges="edges"
            v-if="details.length"/>
      </div>
    </div>

    <div class="form-table">
      <h4>Деталі
        <button class="button" :disabled="materials.length == 0" id="addNewDetail"
                @click="addNewDetail"><i class="fa fa-plus"></i></button>
      </h4>
      <div class="table-wrapper" data-simplebar>
        <table id="main_table" class="main_table table table-bordered">
          <thead>
          <tr>
            <th rowspan="2" style="min-width: 22px !important;">№</th>
            <th rowspan="2" style="min-width:80px !important;">Опції</th>
            <th colspan="2" style="min-width:62px !important;">Довжина</th>
            <th colspan="2" style="min-width:62px !important;">Ширина</th>
            <th rowspan="2" style="min-width:40px !important;">К-ть</th>
            <th v-b-tooltip.hover.right title="Обертати" rowspan="2" style="min-width:30px !important;">
                            <span>
                                <i class="fa fa-sync"></i>
                                <br>
                                <i class="fa fa-xs fa-question-circle"></i>
                            </span>
              <input type="checkbox" id="rotate_all" @change="rotateAll" class="checkbox-input">
              <label for="rotate_all"></label>
            </th>
            <th colspan="4" style="min-width:963px !important;">Кромка</th>
            <th rowspan="2" style="min-width:242px !important;">Матеріал</th>
            <th colspan="3" style="min-width:91px !important;">Стяжка</th>

            <th v-b-tooltip.hover.right title="0-Прозорий, 1-Білий" rowspan="2" style="min-width:46px !important;">
                            <span>
                                Клей <br> PUR<i class="fa fa-xs fa-question-circle"></i>
                            </span>
            </th>
            <th rowspan="2" style="min-width:82px !important;">
                            <span>
                                Порізка за <br> текстурою
                            </span>
            </th>
            <th rowspan="2" style="min-width:120px !important;">Шаблон</th>
            <th rowspan="2" style="min-width:110px !important;">Виріб</th>
            <th rowspan="2" style="min-width:110px !important;">Деталь</th>
          </tr>
          <tr>
            <td style="min-width:53px !important;">мм</td>
            <td v-b-tooltip.hover.right title="Кількість сторін, що обклеюються, за довжиною"
                style="min-width:37px !important;">К
              <i class="fa fa-xs fa-question-circle"></i>
            </td>

            <td style="min-width:53px !important;">мм</td>
            <td v-b-tooltip.hover.right title="Кількість сторін, що обклеюються, за шириною" style="min-width:37px !important;">К
              <i class="fa fa-xs fa-question-circle"></i>
            </td>

            <td style="min-width: 242px !important;">Верх</td>
            <td style="min-width: 242px !important;">Низ</td>
            <td style="min-width: 242px !important;">Ліворуч</td>
            <td style="min-width: 242px !important;">Праворуч</td>

            <td v-b-tooltip.hover.right title="0 - Відсутня, 1-Звичайна, >1-Економ (Обов'язковий вибір Базової деталі 'БД')" style="min-width: 62px !important;"> <span>
                                Тип <i class="fa fa-xs fa-question-circle"></i>
                            </span>
            </td>
            <td v-b-tooltip.hover.right title="Базова деталь для Економ стяжки" colspan="2" style="min-width: 41px !important;">
              БД
              <i class="fa fa-xs fa-question-circle"></i>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(detail, index) in details" :data-key="index" :key="index"
              :class="index === activeIndex ? 'active' : ''"
              @keypress="visual(index)" @keyup="visual(index)" @mousedown="visual(index)">
            <td style="min-width: 22px !important;">{{ index + 1 }}</td>
            <td style="min-width: 80px !important;">
              <div class="btn-group">
                <button class="button button__icon" data-toggle="tooltip" data-placement="right"
                        data-title="Копіювати"
                        @click="copyDetail(index)">
                  <img :src="require('@/assets/img/copy.png')">
                </button>

                <button class="button button__icon" data-toggle="tooltip" data-placement="right" data-title="Видалити"
                        @click="removeDetail(index)">
                  <img :src="require('@/assets/img/trash-can.png')">
                </button>

              </div>
            </td>

            <td style="min-width: 53px !important;" :class="{'red tooltiper': detailValidation(index, 'L_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'L_')">
              <input type="number" step="0.1" min="20" max="9999.9" lang="en-150"
                     @keyup.enter="focusNextInput"
                     @keyup="onlyNumeric"
                     v-model.number="detail.length"/>
            </td>
            <td style="min-width: 37px !important;">
              <input type="text" @keyup="onlyNumeric" step="1" min="0" max="2"
                     @keyup.enter="focusNextInput"
                     :value="(detail.edge_b != null && detail.edge_t != null) ? 2 : (detail.edge_t != null ? 1 : 0)"
                     @input="addEdge($event.target.value, index, 1)"
                     class="sm-one"/>
            </td>
            <td style="min-width: 53px !important;" :class="{'red tooltiper': detailValidation(index, 'W_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'W_')">
              <input type="number" step="0.1" min="20" max="9999.9" lang="en-150"
                     @keyup.enter="focusNextInput"
                     @keyup="onlyNumeric"
                     v-model.number="detail.width"/>
            </td>
            <td style="min-width: 37px !important;">
              <input type="number" @keyup="onlyNumeric" step="1" min="0" max="2"
                     @keyup.enter="focusNextInput"
                     :value="(detail.edge_r != null && detail.edge_l != null) ? 2 : (detail.edge_l != null ? 1 : 0)"
                     @input="addEdge($event.target.value, index, 2)"
                     class="sm-one"/>
            </td>
            <td style="min-width: 40px !important;" :class="{'red tooltiper': detailValidation(index, 'Qt_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'Qt_')">
              <input type="number"
                     @keyup.enter="focusNextInput"
                     @keyup="onlyNumeric" step="1" min="1" lang="en-150"
                     v-model.number="detail.count" class="one-qt"/>
            </td>
            <td style="min-width: 30px !important;"
                :class="{'red tooltiper': detailValidation(index, 'Rotate_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'Rotate_')">
              <input type="checkbox" :id="'Rotate_'+index" class="checkbox-input"
                     v-model.number="detail.rotate"
                     @keyup.enter="focusNextInput"
                     @change="checkboxChange($event.target.checked, 'rotate')">
              <label :for="'Rotate_'+index"></label>
            </td>

            <td style="min-width: 242px !important;"
                :class="{'red tooltiper': detailValidation(index, 'EdgeT_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'EdgeT_')">
              <select class="custom-select three"
                      :style="{color:edges.find(el => el.ref === detail.edge_t)?.color || 'black'}"
                      @keyup.enter="focusNextInput"
                      v-model="detail.edge_t"
                      @keydown="selectByKey($event, edges, 'edge_t')">
                <option value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 242px !important;"
                :class="{'red tooltiper': detailValidation(index, 'EdgeB_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'EdgeB_')">
              <select class="custom-select three"
                      :style="{color:edges.find(el => el.ref === detail.edge_b)?.color || 'black'}"
                      @keyup.enter="focusNextInput"
                      v-model="detail.edge_b"
                      @keydown="selectByKey($event, edges, 'edge_b')">
                <option value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 242px !important;"
                :class="{'red tooltiper': detailValidation(index, 'EdgeL_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'EdgeL_')">
              <select class="custom-select three"
                      @keyup.enter="focusNextInput"
                      :style="{color:edges.find(el => el.ref === detail.edge_l)?.color || 'black'}"
                      v-model="detail.edge_l"
                      @keydown="selectByKey($event, edges, 'edge_l')">
                <option value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 242px !important;"
                :class="{'red tooltiper': detailValidation(index, 'EdgeR_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'EdgeR_')">
              <select class="custom-select three"
                      @keyup.enter="focusNextInput"
                      :style="{color:edges.find(el => el.ref === detail.edge_r)?.color || 'black'}"
                      v-model="detail.edge_r"
                      @keydown="selectByKey($event, edges, 'edge_r')">
                <option value="null">Ні</option>
                <option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ edge.full_name }}
                </option>
              </select>
            </td>

            <td style="min-width: 242px !important;">
              <select v-if="materials.length" class="custom-select three"
                      v-model="detail.material"
                      @keyup.enter="focusNextInput"
                      @keydown="selectByKey($event, materials, 'material')">
                <option value="null" disabled>Виберіть*</option>
                <option v-for="(material, i) in materials" :value="material.ref" :key="i">
                  {{ (i + 1) + ' ' }} {{ material.full_name }}
                </option>
              </select>
            </td>
            <td style="min-width: 62px !important;"
                :class="{'red tooltiper': detailValidation(index, 'Screed_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'Screed_')">
              <select class="custom-select one"
                      v-model.number="detail.screed"
                      @keyup.enter="focusNextInput"
                      @keydown="selectByKey($event, screeds, 'screed')">
                <option value="0">0</option>
                <option value="1">1</option>
                <option v-for="(screed, i) in screeds" :value="screed" :key="i">{{ screed }}
                </option>
              </select>
            </td>

            <td style="min-width: 41px !important;"
                :class="{'red tooltiper': detailValidation(index, 'ScreedBP_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'ScreedBP_')">
              <input type="checkbox" :id="'ScreedBP_'+index" class="checkbox-input"
                     v-model.number="detail.screed_bp"
                     @keyup.enter="focusNextInput"
                     @change="checkboxChange($event.target.checked, 'screed_bp')">
              <label :for="'ScreedBP_'+index"></label>
            </td>

            <td style="min-width: 46px !important;"
                :class="{'red tooltiper': detailValidation(index, 'GlueType_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'GlueType_')">
              <input type="checkbox" :id="'GlueType_'+index" class="checkbox-input"
                     v-model.number="detail.glue"
                     @keyup.enter="focusNextInput"
                     @change="checkboxChange($event.target.checked, 'glue')">
              <label :for="'GlueType_'+index"></label>
            </td>
            <td style="min-width: 82px !important;"
                :class="{'red tooltiper': detailValidation(index, 'CutToTxtrs_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'CutToTxtrs_')">
              <input type="checkbox" :id="'CutToTxtrs_'+index"
                     @keyup.enter="focusNextInput"
                     class="checkbox-input"
                     v-model.number="detail.cut_to_txtrs"
                     @change="checkboxChange($event.target.checked, 'cut_to_txtrs')">
              <label :for="'CutToTxtrs_'+index"></label>
            </td>
            <td style="min-width: 120px !important;"
                :class="{'red tooltiper': detailValidation(index, 'Template_')}"
                v-b-tooltip.hover.right="detailValidation(index, 'Template_')">
              <b-form-select id="baseTemplate" v-model.number="detail.template"
                             @keyup.enter="focusNextInput"
                             @change="pClear(index)">
                <b-form-select-option value="1">Відсутній</b-form-select-option>
                <b-form-select-option value="2">Косі різи</b-form-select-option>
                <b-form-select-option value="3">Фрезерування по дузі</b-form-select-option>
                <b-form-select-option value="4">Вибірка чверті</b-form-select-option>
                <b-form-select-option value="5">Паз під ДВП</b-form-select-option>
                <b-form-select-option value="6">Нестандартне фрезерування</b-form-select-option>
                <b-form-select-option value="7">Різ під нахилом пили</b-form-select-option>
                <b-form-select-option value="8">Фрезерування під петлі</b-form-select-option>

              </b-form-select>
            </td>
            <td style="min-width: 110px !important;">
              <input class="one" type="text" readonly

                     v-model="productName"/>
            </td>
            <td style="min-width: 110px !important;">
            <span>
              <input class="one" placeholder="Деталь" type="text"
                     v-model="detail.detail_name"/>
            </span>
            </td>

          </tr>
          </tbody>
        </table>
        <button class="button"
                :disabled="materials.length === 0" id="addNewDetail"
                @click="addNewDetail"><i class="fa fa-plus"></i></button>
      </div>

    </div>
    <search
        :materials="materials"
        :edges="edges"
        v-bind:what="toSearch"/>
  </div>
</template>

<script>
import Edges from './EdgeTable.vue'
import Materials from './MaterialsTable.vue'
import Details from './DetailsNameTable.vue'
import Screeds from './Screeds.vue'
import Visual from './Visual.vue'
import Search from "./search.vue";
import axios from "axios";
import appconfig from "../../app/config";

export default {
  components: {
    Edges, Materials, Details, Screeds, Visual, Search
  },
  props: {
    modalShow: {
      type: Boolean,
    },
    materials : {
      type:Array,
      default: []
    },
    edges : {
      type:Array,
      default: []
    },
    screeds : {
      type:Array,
      default: []
    },
    'title': {
      'type': String,
    },
    product: {
      'type': Object,
    },

  },
  watch: {
    product: {
      handler(value, old) {
        this.productName = value.name;
        this.customer_material = value.customer_material;
        this.customer_edge = value.customer_edge;
        this.edge_size = value.edge_size;
        this.glue = value.glue;
        this.details = value.details;
        this.edgeDefault = this.edges.length > 0 ? this.edges[0] : {};
        this.materialsDefault = this.materials.length > 0 ? this.materials[0] : {};
        this.visual(0);

      },
    }
  },
  data() {
    return {
      loading: false,
      activeIndex: 0,
      resp: null,
      rotateAllValue: 0,
      productName: this.product?.name || '',
      customer_material: this.product?.customer_material || false,
      edge_size: this.product?.edge_size || false,
      customer_edge: this.product?.customer_edge || false,
      glue: this.product?.glue || false,
      toSearch: '',
      selectedDetail: {},
      edgeDefault: this.edges.length > 0 ? this.edges[0] : {},
      materialsDefault: this.materials.length > 0 ? this.materials[0] : {},
      detailsName: [],
      details: this.product?.details || [],
      colors: [
        '#1abc9c', '#c700fa', '#2746ac', '#9b59b6', '#34495e', '#e74c3c',
        '#e67e22', '#f39c12', '#7f8c8d', '#f368e0', '#341f97', '#6D214F',
        '#BDC581', '#fdaf00', '#795548', '#c0392b', '#0984e3', '#55efc4',
        '#fdcb6e', '#e84393', '#2d3436',
      ]
    };
  },
  computed: {
  },
  methods: {
    detailValidation(i, p) {
      i = i + 1;
      if (this.resp && this.resp.Parts != undefined) {
        let item = this.resp.Parts.find(part => part.No == i),
            response = item != undefined && item[p] != undefined ? 'Вірно: ' + item[p] : false;

        if(p === 'Template_' && item) {
          if(item['P1_'] != undefined ||
              item['P2_'] != undefined ||
              item['P3_'] != undefined ||
              item['P4_'] != undefined ||
              item['EdgeA1_'] != undefined
          ) {
            response = 'Помилка у розширеному деталюванні';
          }
        }

        if (item && item[p] === 0) {
          if (p.includes('Edge')) {
            response = 'Вірно: Ні';
          }
          if (p.includes('rotate') || p.includes('screed_bp')) {
            response = 'Вірно: Викл(0)';
          }
        }

        return response;
      }
      return false;
    },
    validateOrder() {
      this.loading = true;
      let order = this.getOrderObject();
      order.check_parts = true;
      this.$toast.info("Запит у систему обліку")
      axios.post(
          appconfig.Point + 'api/constructor/v1/order/validate',
          this.getOrderObject()
      ).then(response => {
        response = response.data;
        this.resp = response;
        if (response.Error == true) {
          if (typeof response.ErrorMsg != 'undefined') {
            this.$toast.error(response.ErrorMsg)
          } else {
            this.$toast.error("У деталіровці знайдені помилки! Зверніть увагу на червоні виділення полів, при наведенні на які буде показано правильний варіант.")
          }
        } else {
          this.$toast.success('Помилок не знайдено!')
        }
      }).then(r => {
        this.loading = false;
      })
    },
    focusNextInput(e) {
      let nextInput = e.target.parentElement.nextElementSibling || e.target.parentElement.parentElement.nextElementSibling;
      nextInput = nextInput.querySelector('input') || nextInput.querySelector('select');
      if (nextInput) {
        nextInput.focus();
        if (typeof nextInput.select == 'function') {
          nextInput.select();
        }
      }
    },
    rotateAll() {
      this.rotateAllValue = this.rotateAllValue === 1 ? 0 : 1;
      this.details.forEach((item) => item.rotate = this.rotateAllValue)
    },
    pClear(i) {
      this.detail.p1 = 0;
      this.detail.p2 = 0;
      this.detail.p3 = 0;
      this.detail.p4 = 0;
      this.detail.edge_template = null;
      this.detail.note = '';
      this.detail.file = '';

      this.details[i].p1 = 0;
      this.details[i].p2 = 0;
      this.details[i].p3 = 0;
      this.details[i].p4 = 0;
      this.details[i].edge_template = null;
      this.details[i].note = '';
      this.details[i].file = '';
    },
    addAdditional(type, data) {
      if(type === 'edges') {
        data['color'] = this.colors[this.edges.length + 1]
      }
      this[type].push(data);
      if(type === 'edges' && Object.keys(this.edgeDefault).length === 0) {
        this.edgeDefault = data;
      }
      if(type === 'materials' && Object.keys(this.materialsDefault).length === 0) {
        this.materialsDefault = data;
      }
    },
    visual(i) {
      this.selectedDetail = this.details[i];
      this.activeIndex = i;

    },
    copyDetail() {
      let d = {...this.selectedDetail};
      this.details.push(d);
    },
    removeDetail(i) {
      this.details.splice(i, 1);
      if (this.details.length === 0) {
        this.selectedDetail = {};
      } else {
        this.selectedDetail = this.details[this.details.length - 1];
      }
    },
    getOrderObject() {
      let production_params;
      production_params = this.$store.state.auth.user.client.production_params[0];
      return {
        "dept": "ТПМ",
        "section": production_params.section,
        "stock": this.$store.state.auth.user.defaultStock,
        "cy": "980",//980
        "manager": production_params.manager,
        "price_type": production_params.price_type,
        "draw_cut": false,
        "to_work": false,
        "check_parts": false,
        "customer": this.$store.state.auth.user.client.ref,
        "name": this.productName,
        "customer_material": this.customer_material,
        "customer_edge": this.customer_edge,
        "glue": this.glue,
        "edge_size": this.edge_size,
        "items": this.details.map(detail => {detail.name = this.productName; return detail}),
      };
    },
    save() {
      this.$emit('on-submit', this.getOrderObject());
    },
    addEdge(v, i, t) {

      let defEdge = this.edgeDefault;
      if (this.edges.length === 0 || defEdge === undefined) return;

      if (v < 0) v = 0;

      if (v > 0 && t == 1) {
        this.details[i].edge_t = defEdge.ref;
        if (v == 2) {
          this.details[i].edge_b = defEdge.ref;
        } else {
          this.details[i].edge_b = null;
        }
      } else if (v == 0 && t == 1) {
        this.details[i].edge_t = null;
        this.details[i].edge_b = null;
      }

      if (v > 0 && t == 2) {
        this.details[i].edge_l = defEdge.ref;
        if (v == 2) {
          this.details[i].edge_r = defEdge.ref;
        } else {
          this.details[i].edge_r = null;
        }
      } else if (v == 0 && t == 2) {
        this.details[i].edge_l = null;
        this.details[i].edge_r = null;
      }
    },
    addNewDetail() {
      let cleanDeteil = {
        "length": 20,
        "width": 20,
        "count": 1,
        "rotate": 0,
        "edge_t": null,
        "edge_b": null,
        "edge_l": null,
        "edge_r": null,
        "material": this.materialsDefault ? this.materialsDefault.ref : null,
        "note": '',
        "template": 1,
        "p1": 0,
        "p2": 0,
        "p3": 0,
        "p4": 0,
        "edge_template": null,
        "screed": 0,
        "screed_bp": 0,
        "glue": 0,
        "cut_to_txtrs": 0,
        "name": this.productName,
        "detail_name": '',
        "file": '',
      };
      this.details.push(cleanDeteil);
      this.selectedDetail = this.details[this.details.length - 1];
    },
    checkbox(e, v) {
      if (e.key == 0) {
        this.details[v] = 0;
      } else if (e.key == 1) {
        this.details[v] = 1;
      }
    },
    checkboxChange(val, v) {
      this.details[v] = val ? 1 : 0;
    },
    onFileChanged($event, v) {
      const target = $event.target;
      if (target && target.files) {
        this.details[v] = target.files[0];
      }
    },
    selectByKey(e, data, v) {
      let val = e.key;
      if (+(val - 1) != NaN && data[val - 1] != undefined && data[val - 1].ref != undefined) {
        this.details[v] = data[val - 1].ref;
      }
      if (val == 0 && v != 'template') {
        this.details[v] = null;
      }
    },
    onlyNumeric(e) {
      if (e.key != '.' && e.key != ',') {
        e.target.value = e.target.value.replace(/[^0-9\.\,]+/g, '')
      }

    }
  },
  mounted() {
    this.$root.$on('on-save', (type, data) => {
      this.addAdditional(type, data);
    });
    this.$root.$on('on-remove', (type, index) => {
      this[type].splice(index, 1);
    });
    this.$root.$on('setEdgeDefault', (value) => {
      this.edgeDefault = value;
    });
    this.$root.$on('setMaterialDefault', (value) => {
      this.materialsDefault = value;
    });
  },
  name: "product-form"
}
</script>

<style scoped>
tr.active {
  background-color: #d2d2d2;
}

tr.active * {
  background-color: #d2d2d2;
}
</style>