<template>
  <div class="modal fade" id="analogsModal" style="overflow: auto!important;" tabindex="-1" role="dialog"
       aria-labelledby="analogsModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="max-width: 1060px" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title" id="analogsModalLabel">Аналоги</h5>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <template v-if="material">
              <b-media>
                <template #aside>
                  <b-img width="64" :src="appconfig.Point+`/img/products/`+material.code+`_1.jpg`"></b-img>
                </template>
                <b-row>
                  <b-col md="9">
                    <b-row>
                      <b-col md="12">
                        <h5 class="mt-0">{{ material.full_name }}</h5>
                      </b-col>
                      <b-col md="12">
                        <p style="font-size: 13px">
                          <span class="text-muted">  Код: </span>{{ material.code }}
                          <span class="text-muted"> | </span>
                          <span class="item__sku">
                       <span class="item__sku--terms"
                             v-if="material.quantity == 0"> Термін постачання до: {{ material.terms }}</span>
                        <span class="item__sku--instock" v-else> В наявності</span>
                    </span>
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="3">
                    <div class="item__price-wrap" v-if="material.price > 0">
                        <span class="item__price">
                            {{ material.price }}
                        </span>
                              <span class="item__price-cur">
                          {{ material.cy_name }}
                        </span>
                              <span v-if="what === 'materials'" class="item__rat io">
                           / {{ material.base_unit_name }}
                        </span>
                              <span v-else class="item__ratio">
                           / {{ material.sale_unit_name }}
                        </span>
                    </div>
                  </b-col>
                </b-row>
              </b-media>
            </template>

          <table class="table recommends" v-if="analogs.length">
            <tbody>
            <tr v-for="(item, index) in analogs" :key="index">
              <td class="pb-0">
                <div class="search__image-wrap">
                  <img height="70" :src="appconfig.Point+`/img/products/`+item.code+`_1.jpg`"
                       alt="">
                  <figcaption v-if="item.on_order" class="on_order_label">Під замовлення</figcaption>
                </div>
              </td>
              <td>
                {{ item.full_name }}
                <p class="mt-4 mb-0" style="font-size: 13px">
                  <span class="text-muted">  Код: </span>{{ item.code }}
                  <span class="text-muted"> | </span>
                  <span class="item__sku">
                       <span class="item__sku--terms"
                             v-if="item.quantity == 0"> Термін постачання до: {{ item.terms }}</span>
                        <span class="item__sku--instock" v-else="item.quantity > 0"> В наявності</span>
                    </span>
                </p>
              </td>
              <td class="search__price">
                <div class="item__sku--price">
                  <div class="item__price-wrap" v-if="item.price > 0">
                <span class="item__price" v-if="what == 'materials'">
                  {{ (+item.p).toFixed(2) }}
                </span>
                    <span class="item__price" v-else>
                    {{ item.price }}
                </span>
                    <span class="item__price-cur">
                  {{ item.cy_name }}
                </span>
                    <span v-if="what == 'materials'" class="item__rat io">
                   / {{ item.base_unit_name }}
                </span>
                    <span v-else class="item__ratio">
                   / {{ item.sale_unit_name }}
                </span>
                  </div>

                </div>
              </td>
              <td>
                <div class="d-flex justify-content-between" v-if="item.price > 0">
                  <button class="button" :id="'_'+item.ref"
                          @click="takeItem(item)"
                          :disabled="exists(item.ref)">
                    Обрати
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';

export default {
  name: "recommendRender",
  props: ['what', 'responseData'],
  data() {
    return {
      appconfig: appconfig,
      analogs: [],
      material: false,
      received: [],
      loading: false,
    }
  },
  computed: {
    details() {
      return this.$store.state.data.details;
    },
    materials() {
      return this.$store.state.data.materials;
    },
    edges() {
      return this.$store.state.data.edges;
    },
  },
  methods: {
    exists(ref) {
      return this.materials.find(el => el.ref === ref) || this.edges.find(el => el.ref === ref) ? 'disabled' : false
    },
    takeItem(item) {
      let elem = this[this.what].find(el => el.ref === item.ref);
      if (elem) return;

      //Замена или добавление
      if (app.replace != null) {
        let toReplaceIndex = this[this.what].findIndex(el => el.ref === app.replace);
        if (confirm('Замінити ' + this[this.what][toReplaceIndex].full_name + ' на ' + item.full_name)) {
          this[this.what].splice(toReplaceIndex, 1);
          this.replaceInDetails(item); //change in details
          app.replace = null;
          this[this.what].push(item);
          if (this.what === 'materials') {
            this.$store.commit('data/SET_MATERIALS', this[this.what]);
          }
          if (this.what === 'edges') {
            this.$store.commit('data/SET_EDGES', this[this.what]);
          }
        }
        document.getElementById('_' + item.ref).setAttribute("disabled", "disabled");

      } else {
        // this[this.what].push(item);
        document.getElementById('_' + item.ref).setAttribute("disabled", "disabled");
        if (this.what === 'materials') {
          this.$store.commit('data/ADD_MATERIALS', item);
        }
        if (this.what === 'edges') {
          this.$store.commit('data/ADD_EDGES', item);
        }
      }
      this.$forceUpdate();
    },
    replaceInDetails(item) {
      if (this.what === 'materials') {
        this.details
            .filter(el => el.material === app.replace)
            .forEach(i => {
              i.material = item.ref;
            });
      } else if (this.what === 'edges') {
        this.details
            .filter(el => el.edge_t === app.replace)
            .forEach(i => {
              i.edge_t = item.ref;
            });
        this.details
            .filter(el => el.edge_b === app.replace)
            .forEach(i => {
              i.edge_b = item.ref;
            });
        this.details
            .filter(el => el.edge_l === app.replace)
            .forEach(i => {
              i.edge_l = item.ref;
            });
        this.details
            .filter(el => el.edge_r === app.replace)
            .forEach(i => {
              i.edge_r = item.ref;
            });
        this.details
            .filter(el => el.edge_template === app.replace)
            .forEach(i => {
              i.edge_template = item.ref;
            });
      }
      this.$store.commit('data/SET_DETAILS', this.details);
    },
    takeItemEdges(item) {
      this.$root.$emit('edges_change', {'edges': item.recommends || [], 'material': item});
      $('#searchModal').modal('hide');
      $('#recommendModal').modal();
    },
    takeItemAnalogs(item) {
      this.$root.$emit('analogs_change', {'analogs': item.analogs || [], 'material': item, what: this.what});
      $('#searchModal').modal('hide');
      $('#analogsModal').modal();

    },
    receive() {
      let request = '';
      this.analogs.forEach((el, i) => {
        request += 'i' + el.code;
      });
      this.loading = true;
      axios.post(appconfig.Point + 'api/constructor/v1/products', {
        by: 'code',
        search: request,
        take: 20,
        token: (this.$store.state.auth.user.token || null)
      })
          .then(response => {
            this.analogs = response.data;
            this.loading = false;
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.$root.$on('analogs_change', data => {
      this.analogs = data.analogs;
      this.material = data.material;
      this.what = data.what;
      this.receive();
    });
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });

  }
}
</script>

<style scoped>
.table td {
  overflow: hidden;
  vertical-align: middle;
}

p {
  letter-spacing: 0.05px;
  word-spacing: -0.93px;
}

.item__sku > span {
  position: relative;
  color: #19459d;
}

.item__sku--terms::before, .item__sku--terms::before {
  content: url('~@/assets/img/car_truck.svg');
  margin-right: 5px;
  top: 2px;
  position: relative;
}

.item__sku--instock::before, .item__sku--instock::before {
  content: '';
  background: url('~@/assets/img/check-circle.svg') no-repeat center;
  margin-right: 5px;
  width: 18px;
  height: 16px;
  display: inline-block;
  top: 3px;
  position: relative;
}

#recommendModal .on_order_label {
  position: relative;
  top: -73px;
  left: -40px;
  z-index: 2;
  padding: 1px 28px;
  font-size: 10px;
  min-width: max-content;
  transform: rotate(-46deg);
  background: orange;
  color: #fff;

}
</style>