<template>
  <div class="modal-list" data-simplebar>
    <table class="table search-response" v-if="responseData.length">
      <tbody>
      <tr v-for="(item, index) in responseData" :key="index">
        <td class="search__image">
          <div class="search__image-wrap">
            <img height="70" :src="appconfig.Point+`/`+item.image" alt="">
            <figcaption v-if="item.on_order" class="on_order_label">Під замовлення</figcaption>
          </div>
        </td>
        <td class="search__name">
          <a class="item__name" target="_blank"
             :href="appconfig.Point+`/personal-office/products/`+item.slug">
            {{ item.full_name }}
          </a>
          <p>
            <span class="text-muted">  Код: </span>{{ item.code }}
            <span class="text-muted"> | </span>
            <span class="item__sku">
                       <span class="item__sku--terms"
                             v-if="item.quantity == 0"> Термін постачання до: {{ item.terms }}</span>
                        <span class="item__sku--instock" v-else="item.quantity > 0"> В наявності</span>
                    </span>
            <button class="button button__small"
                    v-if="item.analogs !== undefined && item.analogs.length > 0"
                    @click="takeItemAnalogs(item)">
              Аналоги
            </button>
          </p>
        </td>
        <td class="search__price">
          <div class="item__sku--price">
            <div class="item__price-wrap" v-if="item.price > 0">
                <span class="item__price" v-if="what == 'materials'">
                  {{ (+item.p).toFixed(2) }}
                </span>
              <span class="item__price" v-else>
                    {{ item.price }}
                </span>
              <span class="item__price-cur">
                  {{ item.cy_name }}
                </span>
              <span v-if="what == 'materials'" class="item__rat io">
                   {{ item.base_unit_name }}
                </span>
              <span v-else class="item__ratio">
                   {{ item.sale_unit_name }}
                </span>
            </div>

          </div>
        </td>
        <td class="search__action" v-if="item.pr > 0">
          <div class="d-flex justify-content-between">
            <button class="button" :id="'_'+item.ref" @click="takeItem(item)"
                    :disabled="exists(item.ref)">
              Обрати
            </button>
            <button v-if="item.recommends != undefined  && item.recommends.length > 0 && what === 'materials'"
                    class="button button__grey" :id="'_'+item.ref" @click="takeItemEdges(item)">
              Кромки
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <span class="search--empty" v-else> Данних немає </span>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "search-render",
  props: ['what', 'responseData', 'materials', 'edges'],
  data() {
    return {
      appconfig: appconfig
    }
  },
  methods: {
    exists(ref) {
      return this[this.what].find(el => el.ref === ref) ? 'disabled' : false
    },
    takeItem(item) {
      let elem = this[this.what].find(el => el.ref === item.ref);
      if (elem) return;

      //Замена или добавление
      if (app.replace != null) {
        let toReplaceIndex = app[this.what].findIndex(el => el.ref === app.replace);
        if (confirm('Замінити ' + app[this.what][toReplaceIndex].full_name + ' на ' + item.full_name)) {
          app[this.what][toReplaceIndex] = item;
          this.replaceInDetails(item); //change in details

          app.replace = null;

          this.$forceUpdate();
          this.$root.$emit('update', true);
        }
      } else {
        app[this.what].push(item);
        this.$root.$emit('on-save', this.what, item);
        document.getElementById('_' + item.ref).setAttribute("disabled", "disabled");
      }

    },
    replaceInDetails(item) {
      if (this.what == 'materials') {
        app.details
            .filter(el => el.Material === app.replace)
            .forEach(i => {
              i.Material = item.ref;
            });
      } else if (this.what == 'edges') {
        app.details
            .filter(el => el.edge_t === app.replace)
            .forEach(i => {
              i.edge_t = item.ref;
            });
        app.details
            .filter(el => el.edge_b === app.replace)
            .forEach(i => {
              i.edge_b = item.ref;
            });
        app.details
            .filter(el => el.edge_l === app.replace)
            .forEach(i => {
              i.edge_l = item.ref;
            });
        app.details
            .filter(el => el.edge_r === app.replace)
            .forEach(i => {
              i.edge_r = item.ref;
            });
        app.details
            .filter(el => el.edge_template === app.replace)
            .forEach(i => {
              i.edge_template = item.ref;
            });
      }
    },
    takeItemEdges(item) {
      this.$root.$emit('edges_change', {'edges': item.recommends || [], 'material': item});
      $('#searchModal').modal('hide');
      $('#recommendModal').modal();
    },
    takeItemAnalogs(item) {
      this.$root.$emit('analogs_change', {'analogs': item.analogs || [], 'material': item});
      $('#searchModal').modal('hide');
      $('#analogsModal').modal();

    },
  }
}
</script>

<style scoped>
.table td {
  overflow: hidden;
  vertical-align: middle;
}

p {
  letter-spacing: 0.05px;
  word-spacing: -0.93px;
}

.item__sku > span {
  position: relative;
  color: #19459d;
}

.item__sku--terms::before, .item__sku--terms::before {
  content: url('~@/assets/img/car_truck.svg');
  margin-right: 5px;
  top: 2px;
  position: relative;
}

.item__sku--instock::before, .item__sku--instock::before {
  content: '';
  background: url('~@/assets/img/check-circle.svg') no-repeat center;
  margin-right: 5px;
  width: 18px;
  height: 16px;
  display: inline-block;
  top: 3px;
  position: relative;
}

</style>